// 生产环境
let config = {
  baseUrl: 'http://localhost:9900', // 生产网关地址
  // baseUrl: 'https://edu.shanxiyixue.com/gateway',
  // baseUrl: 'http://192.168.10.22:9900',
  // baseUrl: 'http://192.168.10.157:9900',
  tokenName: 'EDU_WEB_TOKEN',
  tokenExpires: 60 * 60 * 1000 // token 过期时间为1个小时
}

if (process.env.NODE_ENV === 'development') {
  // 开发环境
  config = {
    ...config,
    baseUrl: 'https://edu.shanxiyixue.com/gateway', // 开发网关地址
    // baseUrl: 'http://192.168.10.77:9900',
    // baseUrl: 'http://localhost:9900',
    // baseUrl: 'http://192.168.10.22:9900', 
    // baseUrl: 'http://192.168.10.157:9900',
    url: 'edu.shanxiyixue.com' // 店铺域名，本地开发使用，生产不用配置该属性
    // url: 'edu.ek-edu.cn' // 店铺域名，本地开发使用，生产不用配置该属性
  }
}
export default config
